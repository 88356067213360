import type { RankMathSeo } from '@/types'

export default function (input: RankMathSeo) {
  return useSeoMeta({
    title: input?.title,
    robots: input?.robots?.toString(),
    description: input?.description,

    ogUrl: input?.openGraph?.url,
    ogType: input?.openGraph?.type,
    ogTitle: input?.openGraph?.title,
    ogImage: { ...input?.openGraph?.image },
    ogLocale: input?.openGraph?.locale,
    ogDescription: input?.openGraph?.description,

    twitterSite: input?.openGraph?.twitterMeta?.site,
    twitterCard: input?.openGraph?.twitterMeta?.card,
    twitterTitle: input?.openGraph?.twitterMeta?.title,
    twitterCreator: input?.openGraph?.twitterMeta?.creator,
    twitterImage: {
      url: input?.openGraph?.twitterMeta?.image,
    },
    twitterDescription: input?.openGraph?.twitterMeta?.description,
  })
}
